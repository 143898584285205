import { formatCep, formatPrice } from '@checkout/utils/formatter'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import React from 'react'
import * as S from './styles'
import { useSelector } from 'react-redux'
import store from '@one-checkout/store'
import { Gift } from 'react-feather'
import { useTheme } from 'styled-components'
import ShippingLabel from '../IdentificationSummary/ShippingLabel'

export function IdentificationSummaryGiftAddress( { onClick }: IdentificationSummaryGiftAddressProps ) {
  const { identification, product, shipping } = useSelector( ( state: store.State ) => ( {
    'identification': state.identification,
    'product': state.product,
    'shipping': state.shipping,
  } ) )
  const theme = useTheme()

  const shippingPrice = shipping.price ?? 0

  if ( !identification.giftAddress.useGiftAddress ) {
    return null
  }

  return (
    <S.ContainerGiftAddress
      onClick={ onClick }
    >
      <S.GiftLabel>
        <Gift color={ theme.v2.palette[ 'TAG1-700' ] } />
        <Typography
          color="TAG1-700"
          variant="text-medium-bold"
        >
          Endereço de entrega
        </Typography>
      </S.GiftLabel>
      <S.Row>
        <S.IndentificationDataSection>
          <Typography
            color="TAG1-700"
            variant="text-medium-bold"
          >
            {
              formatAddressLine1( {
                'street': identification.giftAddress.street.value,
                'number': identification.giftAddress.number.value
              } )
            }
          </Typography>
          <Typography
            color="TAG1-700"
            variant="text-small-regular"
          >
            {
              formatAddressLine2( {
                'complement': identification.giftAddress.complement.value,
                'city': identification.giftAddress.city.value,
                'state': identification.giftAddress.state.value,
                'postalCode': identification.giftAddress.postalCode.value
              } )
            } {shippingPrice > 0 && <b>+{formatPrice( shippingPrice, true )}</b>}
          </Typography>
        </S.IndentificationDataSection>
      </S.Row>
      {
        product!.hideShippingInfo ? null :
          <S.Row>
            <ShippingLabel />
          </S.Row>
      }
    </S.ContainerGiftAddress>
  )
}

type IdentificationSummaryGiftAddressProps = {
  readonly onClick: () => void
}

function formatAddressLine1( address: { street: string, number: string } ): string {
  const { street, number } = address
  return `${street}, ${number}`
}

function formatAddressLine2( address: { complement: string, city: string, state: string, postalCode: string} ): string {
  const { complement, city, state, postalCode } = address
  return `${complement ? complement + '. ' : ''}${city} - ${state}. CEP ${formatCep( postalCode )}`
}
