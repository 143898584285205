import styled from 'styled-components'
import { TypographyV2 } from '@taglivros/tag-components'
import { MOBILE_THRESHOLD } from '@one-checkout/constants'

export const ToggleWrapper = styled.div`
    border-radius: 4px;
    border: 1px solid;
    border-color: ${props => props.theme.v2.palette[ 'TAG1-700' ]};
    margin-top: 18px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    height: 40px;

    @media(max-width: ${MOBILE_THRESHOLD}px) {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 0px;
    }
`

export const ToggleLabel = styled( TypographyV2 )`
  margin-left: 10px;
  font-size: 14px;
` as ( props: TypographyV2.Props ) => any
