import { FormFields } from '@one-checkout/interfaces/FormFields'
import identificationSlice from '@one-checkout/store/identification.slice'

function checkFormValidity( formState: FormFields, section: Section ) {
  const formFields: Record<Section, Array<keyof FormFields>> = {
    'identification': [ 'name', 'email', 'phone', 'postalCode', 'city', 'state', 'street', 'number', 'complement', 'district' ],
    'pix': [ 'documentNumber' ],
    'card': [ 'documentNumber', 'cardNumber', 'expiry', 'cvv', 'nameOnCard' ],
  }

  return isFormValid( formState, formFields[ section ] )
}

function isFormValid( formState: FormFields, fields: Array<keyof FormFields> ) {
  for ( const field of fields ) {
    if ( !formState[ field as keyof FormFields ]!.isValid ) {
      return false
    }
  }

  return true
}

function checkGiftAddressValidity( giftAddress: identificationSlice.GiftAddress ) {
  if ( !giftAddress.useGiftAddress ) {
    return true
  }
  const giftAddressFields: Array<keyof FormFields> = [ 'postalCode', 'city', 'state', 'street', 'number', 'complement', 'district' ]
  return isFormValid( giftAddress, giftAddressFields )
}

type Section = 'identification' | 'pix' | 'card'

export {
  checkFormValidity,
  checkGiftAddressValidity
}

