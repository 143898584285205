import React from 'react'
import * as S from './styles'
import Header from '../Header'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import IconSmartphone from '@site/images/howItWorks/icon-benefits-home-smartphone.png'

function MaintenanceView() {
  return (
    <S.Container>
      <Header />
      <S.Message>
        <S.Title>
          <Typography
            color="TAG1-700"
            variant="subtitle-large-bold"
          >
            Estamos em manutenção!
          </Typography>
        </S.Title>

        <S.Image src={ IconSmartphone } />

        <S.Content>
          <Typography
            color="TAG1-700"
            variant="text-medium-regular"
          >
            Pausa para um ajuste literário! Estamos ajustando os detalhes para deixar sua experiência ainda melhor.
          </Typography>
        </S.Content>
      </S.Message>
    </S.Container>
  )
}

export { MaintenanceView }
