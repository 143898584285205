// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import encrypt from 'adyen-cse-js/js/adyen.encrypt.nodom.min.js'
import { backendClient } from './client'
import cookie from 'cookie'
import validateCaptcha from '@shared/services/validateCaptcha'
import Payment from 'payment'
import { onlyNumbers } from '@checkout/utils/formatter'
import { SubscriptionService } from '@checkout/services'
import Product from '@shared/interfaces/Product'
import Showcase from '@one-checkout/interfaces/Showcase'

export interface CommomPayload {
  coupon: string
  isRecommendationCoupon?: boolean
  identification: {
    name: string
    email: string
    phone: string
    postalCode: string
    city: string
    state: string
    street: string
    number: string
    complement: string
    district: string
    ibge: string
    giftAddress: {
      postalCode?: string
      city?: string
      state?: string
      street?: string
      number?: string
      complement?: string
      district?: string
      ibge?: string
    }
  }
  payment: {
    documentNumber: string
    totalDiscount: number
  }
  category?: {
    id?: number
  },
  plan?: {
    id?: number
  },
  utm?: Utm,
  isLocalSale?: boolean,
}

export type Utm = {
  source: string | null,
  medium: string | null,
  campaign: string | null,
}

export type PixPayload = CommomPayload

export interface CardPayload extends CommomPayload {
  payment: {
    documentNumber: string
    cardNumber: string
    expiry: string
    cvv: string
    nameOnCard: string
    installments: string
    totalDiscount: number
  }
}

export function trailPurchase( productName: keyof Showcase, paymentMethod: 'pix' | 'card', payload: PixPayload | CardPayload ): Promise<any> {
  return purchase( `/v2/pagamento/trilhas?produto=${productName}`, paymentMethod, payload, 'trail' )
}

export function clubPurchase( productName: keyof Showcase, paymentMethod: 'pix' | 'card', payload: PixPayload | CardPayload ): Promise<any> {
  return purchase( `/v2/pagamento/clubes?produto=${productName}`, paymentMethod, payload, 'club' )
}

export function oneTimeOfferPurchase( productName: keyof Showcase, paymentMethod: 'pix' | 'card', payload: PixPayload | CardPayload ): Promise<any> {
  return purchase( `/v2/pagamento/oferta-unica?produto=${productName}`, paymentMethod, payload, 'oneTimeOffer' )
}

export function experiencePurchase( productName: keyof Showcase, paymentMethod: 'pix' | 'card', payload: PixPayload | CardPayload ): Promise<any> {
  return purchase( `/v2/pagamento/experimente?produto=${productName}`, paymentMethod, payload, 'experience' )
}

async function purchase(
  endpoint: string,
  paymentMethod: 'pix' | 'card',
  payload: PixPayload | CardPayload,
  productType: Product[ 'type' ]
): Promise<any> {
  const captcha = await getCaptcha( paymentMethod, productType )
  const customer = parseCustomer( payload )
  const address = parseAddress( payload )
  const giftAddress = parseGiftAddress( payload )
  const payment = paymentMethod === 'pix' ? parsePix() : parseCard( payload as CardPayload )
  const partnerCode = getPartnerCode()
  const requestPayload: any = {
    captcha,
    customer,
    address,
    payment,
    'address_gift': giftAddress,
    'partner_code': partnerCode,
    'category': payload.category,
    'plan': payload.plan,
    'utm': payload.utm,
    'is_local_sale': payload.isLocalSale,
  }

  if ( payload.isRecommendationCoupon ) {
    requestPayload[ 'referral_code' ] = payload.coupon
  } else {
    requestPayload[ 'coupon' ] = payload.coupon
  }

  try {
    const { data } = await backendClient.post( endpoint, requestPayload )

    return data
  } catch ( error: any ) {
    if ( error.response ) {
      const errorData = { ...error.response.data, 'errorReason': error.response.data[ 'error_reason' ] }
      delete error.response.data[ 'error_reason' ]
      return errorData
    }
  }
}

export async function getPixPaymentStatus( code: string ): Promise<'pending' | 'paid'> {
  const { data } = await SubscriptionService.get( code )

  return data.status === 'COBRADO' ? 'paid' : 'pending'
}

export function getUtmParamFromUrl(): Utm {
  const query = new URLSearchParams( location.search )

  const source = query.get( 'utm_source' )
  const medium = query.get( 'utm_medium' )
  const campaign = query.get( 'utm_campaign' )

  return { source, medium, campaign }
}

export function getIsLocalSaleParamFromUrl(): boolean {
  const query = new URLSearchParams( location.search )

  const source = query.get( 'source' )

  if ( source?.trim().toLowerCase() === 'local' ) {
    return true
  }

  return false
}

function getCaptcha( paymentMethod: 'pix' | 'card', productType: Product[ 'type' ] ): Promise<string> {
  return validateCaptcha( `${productType === 'trail' ? 'Trilhas' : 'Clube'}Payment${paymentMethod === 'pix' ? 'Pix' : 'CreditCard'}` )
    .then( token => token || '' )
}

function parseCustomer( payload: CommomPayload ) {
  const { name, email, phone } = payload.identification
  const { documentNumber } = payload.payment
  const [ firstName, ...lastName ] = name.split( /\s+/ ) as [string, string]

  return {
    'document_number': documentNumber.replace( /\D/g, '' ),
    email,
    'first_name': firstName,
    'last_name': lastName.join( ' ' ),
    'telephone': phone.replace( /\D/g, '' ),
  }
}

function parseAddress( payload: CommomPayload ) {
  const {
    city,
    complement,
    district,
    number,
    postalCode,
    state,
    street,
    ibge,
  } = payload.identification

  return {
    district,
    'postal_code': postalCode.replace( /\D/g, '' ),
    city,
    complement,
    number,
    street,
    state,
    ibge,
  }
}

function parseGiftAddress( payload: CommomPayload ) {
  if ( !payload.identification?.giftAddress || Object.keys( payload.identification?.giftAddress ).length === 0 ) {
    return null
  }

  const {
    city,
    complement,
    district,
    number,
    postalCode,
    state,
    street,
    ibge,
  } = payload.identification.giftAddress

  return {
    district,
    'postal_code': postalCode!.replace( /\D/g, '' ),
    city,
    complement,
    number,
    street,
    state,
    ibge,
  }
}

function parsePix() {
  return {
    'method': 'PIX'
  }
}

function parseCard( payload: CardPayload ) {
  const {
    cardNumber,
    cvv,
    expiry,
    nameOnCard,
    installments,
  } = payload.payment

  const [ expiryMonth, expiryYear ] = expiry.split( '/' ) as [string, string]

  return {
    'method': 'CREDIT_CARD',
    'credit_card': {
      'expiry_year': Number( '20' + expiryYear ),
      'expiry_month': Number( expiryMonth ),
      'holder_name': nameOnCard,
      'last_numbers': cardNumber.substring( cardNumber.length - 4 ),
      'token': createCardToken( cardNumber, expiryYear, expiryMonth, cvv, nameOnCard ),
      'brand': getCardBrand( cardNumber )
    },
    'installments': Number( installments ),
  }
}

function getCardBrand( number: string ): string {
  return Payment.fns.cardType( onlyNumbers( number ) )
}

function createCardToken( number: string, expiryYear: string, expiryMonth: string, cvv: string, nameOnCard: string ): string {
  const criptoKey = process.env[ 'GATSBY_CHAVE_CRIPTO_ADYEN' ]!
  const cseInstance = encrypt.createEncryption( criptoKey, {} )

  const validationCard = {
    'cvc': cvv,
    expiryMonth,
    'expiryYear': '20' + expiryYear,
    'generationtime': new Date().toISOString(),
    'holderName': nameOnCard,
    number,
  }

  if ( !cseInstance.validate( validationCard ).valid ) {
    throw new Error()
  }

  return cseInstance.encrypt( validationCard )
}

function getPartnerCode(): string | undefined {
  return cookie.parse( document.cookie )[ 'partner_code' ]
}
