import { backendClient } from './client'

export const getToggle = async( toggleName: string ): Promise<boolean> => {
  try {
    const endpoint = `/v2/toggle/${toggleName}`
    const { data } = await backendClient.get( endpoint )
    return data.enabled
  } catch ( error: any ) {
    return false
  }
}
