/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { Spinner } from '@checkout/components/atoms/Spinner'
import { Form } from '@one-checkout/components/Form'
import { validate } from '@one-checkout/utils/validation'
import React, { useState } from 'react'
import { HelpCircle, Truck, Gift } from 'react-feather'
import { FormInfoPill } from '../FormInfoPill'
import { FormFields, FieldId, FieldData } from '@one-checkout/interfaces/FormFields'
import { FormInput } from '@one-checkout/components/FormInput'
import { verifySubscriptionStatusByEmail } from '@one-checkout/actions/subscription.action'
import { registerLead } from '@one-checkout/actions/lead.action'
import { addShippingInfoErrorEvent, addShippingInfoEvent, addShippingInfoRequestEvent, beginShippingInfoEvent } from '@one-checkout/actions/event.action'
import { getAddress, getShippingInfoPillText } from '@one-checkout/actions/identification.action'
import identificationSlice from '@one-checkout/store/identification.slice'
import shippingSlice from '@one-checkout/store/shipping.slice'
import { applyCoupon } from '@one-checkout/actions/promotionCoupon.action'
import store from '@one-checkout/store'
import { setCollapseIdentificationForm } from '../../actions/step.action'

export function IdentificationForm( props: IdentificationForm.Props ) {
  const {
    fields,
    displayAddress,
    isActiveSubscriber,
    isProductSubscription,
    onValidPostalCode,
    onInvalidPostalCode,
    onChange,
    onSubmit,
    hideShippingInfo,
  } = props

  const [ waitingAddress, setWaitingAddress ] = useState( false )
  const [ waitingEmail, setWaitingEmail ] = useState( false )
  const [ verifiedEmail, setVerifiedEmail ] = useState( '' )
  const {
    product,
    promotionCoupon,
    identification,
  } = store.getState()

  async function handleChange( id: FieldId, value: string, isValid: boolean, helperText: string ): Promise<void> {
    let verifiedAndIsValid = isValid
    if ( id === 'email' ) {
      verifiedAndIsValid = false
    }
    onChange( id, value, verifiedAndIsValid, helperText )

    if ( id === 'postalCode' && isValid ) {
      try {
        setWaitingAddress( true )
        addShippingInfoRequestEvent()
        const {
          identificationData,
          shippingData,
        } = await getAddress( value )

        const isCouponApplied = promotionCoupon.state === 'applied'
        if ( isCouponApplied && !identification.giftAddress.useGiftAddress ) {
          await applyCoupon()
        }
        addShippingInfoEvent( JSON.stringify( formatShippingDataToEvent( identificationData, shippingData ) ) )
        onValidPostalCode()
      } catch ( error ) {
        onInvalidPostalCode( value )
        const { errors } = error as any
        addShippingInfoErrorEvent( errors[ 0 ].message )
      } finally {
        setWaitingAddress( false )
      }
    }

    const getDefaultVerificationRule = ( fieldId: FieldId, field: FieldData ): boolean => id === fieldId && isValid && value !== '' && value.length - 1 > field.value.length
    const createValidationObject = ( rule: ( args: any[] ) => boolean, validationFunction: ( args: any[] ) => void | Promise<void> ) => ( {
      rule,
      'validate': validationFunction
    } )

    const fieldValidationMap = new Map()
    fieldValidationMap.set( 'email', createValidationObject( () => getDefaultVerificationRule( 'email', fields.email ), () => verifyEmail( value ) ) )
    fieldValidationMap.set( 'complement', createValidationObject( () => getDefaultVerificationRule( 'complement', fields.complement ), () => genericFieldVerification( 'complement', value ) ) )
    fieldValidationMap.set( 'district', createValidationObject( () => getDefaultVerificationRule( 'district', fields.district ), () => genericFieldVerification( 'district', value ) ) )

    if ( fieldValidationMap.has( id ) && fieldValidationMap.get( id ).rule() ) {
      fieldValidationMap.get( id ).validate()
    }
  }

  const genericFieldVerification = ( fieldId: FieldId, value: string ) => {
    const validation = validate( fieldId, value )
    onChange( fieldId, value, validation.isValid, validation.helperText )
  }

  async function verifyEmail( value: string ) {
    if ( waitingEmail || verifiedEmail === value ) {
      return
    }
    setWaitingEmail( true )
    setVerifiedEmail( value )
    const validation = validate( 'email', value )!

    if ( validation.isValid ) {
      await verifySubscriptionStatusByEmail( value )
      registerLead( fields.name.value, value, fields.phone.value )
    }

    onChange( 'email', value, validation.isValid, validation.helperText )
    setWaitingEmail( false )
  }

  function formatShippingDataToEvent( identificationData: identificationSlice.State, shippingData: shippingSlice.State ) {
    return {
      'postalCode': identificationData.postalCode.value,
      'city': identificationData.city.value,
      'state': identificationData.state.value,
      'street': identificationData.street.value,
      'district': identificationData.district.value,
      'ibge': identificationData.ibge,
      'price': shippingData.price,
      'deadline': shippingData.deadline,
    }
  }

  function handleFocus( id: FieldId ) {
    if ( id === 'postalCode' ) {
      beginShippingInfoEvent()
    }
  }

  const nameInput: FormInput.Props = {
    ...fields.name,
    'label': 'Nome',
    'id': 'name',
    'maxLength': 100,
  }

  const emailInput: FormInput.Props = {
    ...fields.email,
    'label': 'E-mail',
    'id': 'email',
    'maxLength': 250,
    'InfoPill': product!.enableMemberDiscount && isActiveSubscriber && !isProductSubscription ? () => (
      <FormInfoPill
        Icon={ Gift }
        text="Desconto Associado TAG"
        backgroundColor="#00A94E1A"
        color="CTA-400"
      />
    ) : null,
    'onBlur': () => {
      const promise = verifyEmail( fields.email.value )
      Promise.resolve( promise )
    },
    'Icon': () => {
      return waitingEmail ? (
        <Spinner
          show={ true }
          isColored={ true }
          width={ 25 }
          height={ 25 }
        />
      ) : null
    },
  }

  const phoneInput: FormInput.Props = {
    ...fields.phone,
    'label': 'Telefone',
    'id': 'phone',
    'maxLength': 15,
    'required': true,
    'helperText': 'Número com DDD e digito 9 no início, caso houver.',
    'onBlur': () => {
      const validation = validate( 'phone', fields.phone.value )!

      onChange( 'phone', fields.phone.value, validation.isValid, validation.helperText )

      if ( validation.isValid && fields.email.isValid ) {
        registerLead( fields.name.value, fields.email.value, fields.phone.value )
      }
    }
  }

  const postalCodeInput: FormInput.Props = {
    ...fields.postalCode,
    'label': 'CEP',
    'id': 'postalCode',
    'maxLength': 10,
    'Icon': () => {
      return waitingAddress ? (
        <Spinner
          show={ true }
          isColored={ true }
          width={ 25 }
          height={ 25 }
        />
      ) : (
        <HelpCircle
          className="is-clickable"
          onClick={ () => window.open( 'https://buscacepinter.correios.com.br/app/localidade_logradouro/index.php', '__blank', 'noopener' ) }
        />
      )
    },
    'InfoPill': displayAddress && !hideShippingInfo && !identification.giftAddress.useGiftAddress ? () => (
      <FormInfoPill
        Icon={ Truck }
        text={ getShippingInfoPillText() }
        backgroundColor="#222F641A"
        color="TAG1-700"
      />
    ) : null,
  }

  const cityInput: FormInput.Props = {
    ...fields.city,
    'label': 'Cidade',
    'id': 'city',
    'maxLength': 40,
    'disabled': true,
    'inputWidth': {
      'mobile': '75%',
      'desktop': '75%',
    }
  }

  const stateInput: FormInput.Props = {
    ...fields.state,
    'label': 'Estado',
    'id': 'state',
    'maxLength': 2,
    'disabled': true,
    'inputWidth': {
      'mobile': '25%',
      'desktop': '25%',
    },
  }

  const streetInput: FormInput.Props = {
    ...fields.street,
    'label': 'Rua',
    'id': 'street',
    'maxLength': 60,
  }

  const numberInput: FormInput.Props = {
    ...fields.number,
    'label': 'Número',
    'id': 'number',
    'maxLength': 5,
    'inputWidth': {
      'mobile': '25%',
      'desktop': '25%',
    },
    'autoFocus': true,
    'onBlur': () => {
      if ( identification.number.isValid ) {
        setCollapseIdentificationForm( true )
      }
    },
  }

  const complementInput: FormInput.Props = {
    ...fields.complement,
    'label': 'Complemento',
    'id': 'complement',
    'maxLength': 50,
    'inputWidth': {
      'mobile': '75%',
      'desktop': '75%',
    },
  }

  const districtInput: FormInput.Props = {
    ...fields.district,
    'label': 'Bairro',
    'id': 'district',
    'maxLength': 50,
  }

  const initialFields = [ [ nameInput ], [ emailInput ], [ phoneInput ], [ postalCodeInput ] ]
  const completeForm = [ ...initialFields, [ cityInput, stateInput ], [ streetInput ], [ numberInput, complementInput ], [ districtInput ] ]

  return (
    <Form
      inputFields={ !displayAddress ? initialFields : completeForm }
      onSubmit={ onSubmit }
      onChange={ handleChange }
      onFocus={ handleFocus }
    />
  )
}

export namespace IdentificationForm {
  export interface Props {
    onChange( id: FieldId, value: string, isValid: boolean, helperText: string ): void
    onSubmit(): void
    fields: Fields
    displayAddress: boolean
    hideShippingInfo?: boolean
    isActiveSubscriber: boolean
    isProductSubscription: boolean
    onValidPostalCode(): void
    onInvalidPostalCode( invalidPostalCode?: string ): void
  }

  export type Fields = (
    Required<
      Pick<
        FormFields,
        | 'email'
        | 'name'
        | 'phone'
        | 'postalCode'
        | 'city'
        | 'state'
        | 'street'
        | 'number'
        | 'complement'
        | 'district'
      >
    >
  )
}
