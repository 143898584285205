import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const defaultField = {
  'value': '',
  'isValid': false,
  'helperText': '',
  'disabled': false,
}

const identificationSlice = createSlice( {
  'name': 'identification',
  'initialState': {
    'name': defaultField,
    'email': defaultField,
    'phone': defaultField,
    'postalCode': defaultField,
    'city': defaultField,
    'state': defaultField,
    'street': defaultField,
    'number': defaultField,
    'complement': {
      ...defaultField,
      'isValid': true,
    },
    'district': defaultField,
    'ibge': '',
    'giftAddress': {
      'useGiftAddress': false,
      'postalCode': defaultField,
      'city': defaultField,
      'state': defaultField,
      'street': defaultField,
      'number': defaultField,
      'complement': {
        ...defaultField,
        'isValid': true,
      },
      'district': defaultField,
      'ibge': '',
    }
  } as identificationSlice.State,
  'reducers': {
    setIdentificationData( state, action: PayloadAction<identificationSlice.SetIdentificationDataPayload> ) {
      Object.assign( state, action.payload )
    },
    resetEmail( state ) {
      state.email = identificationSlice.getInitialState().email
    },
  }
} )
namespace identificationSlice {
  export interface State {
    name: Field
    email: Field
    phone: Field
    postalCode: Field
    city: Field
    state: Field
    street: Field
    number: Field
    complement: Field
    district: Field
    ibge: string
    giftAddress: GiftAddress
  }

  export interface Field {
    value: string
    isValid: boolean
    helperText: string
    disabled: boolean
  }

  export interface GiftAddress {
    useGiftAddress: boolean
    postalCode: Field
    city: Field
    state: Field
    street: Field
    number: Field
    complement: Field
    district: Field
    ibge: string
  }

  export type SetIdentificationDataPayload = Partial<State>
}

export default identificationSlice
