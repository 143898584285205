import React from 'react'
import { ToggleWrapper, ToggleLabel } from './styles'
import { Toggle } from '../Toggle/Toggle'
import { toggleUseGiftAddress } from '@one-checkout/actions/identification.action'
import store from '@one-checkout/store'

export function ToggleGiftAddress() {
  const { identification } = store.getState()

  return (
    <ToggleWrapper>
      <Toggle
        id="toggleUseGiftAddress"
        checked={ identification.giftAddress.useGiftAddress }
        onChange={ () => toggleUseGiftAddress( !identification.giftAddress.useGiftAddress ) }
      />
      <ToggleLabel
        variant="text-medium-regular"
        color="TAG1-800"
      >
        Esse pedido é um presente.
      </ToggleLabel>
    </ToggleWrapper>
  )
}
