import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.v2.palette[ 'TAG1-800' ]};
  align-items: center;
`

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-color: white;
  border-radius: 16px;
  width: auto;
  padding: 16px;
  text-align: center;
  z-index: 1;
  width: 500px;
  height: 300px;
`

export const Title = styled.div``

export const Image = styled.img`
  max-height: 158px;
  max-width: 120px;
  overflow: auto;
`

export const Content = styled.div``
