import React from 'react'
import { Truck as TruckIcon } from 'react-feather'
import * as S from './styles'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import { useTheme } from 'styled-components'
import { getShippingInfoPillText } from '@one-checkout/actions/identification.action'

function ShippingLabel() {
  const theme = useTheme().v2

  return (
    <S.Container>
      <TruckIcon color={ theme.palette[ 'TAG1-700' ] } />
      <Typography
        color="TAG1-700"
        variant="text-medium-bold"
      >
        {getShippingInfoPillText()}
      </Typography>
    </S.Container>
  )
}

export default ShippingLabel
