import React from 'react'
import { Input, Label, Slider } from './styles'

export function Toggle( props: InputProps ) {
  const {
    id,
    checked,
    onChange,
    ...customProps
  } = props
  return (
    <Label
      htmlFor={ id }
    >
      <Input
        id={ id }
        type="checkbox"
        checked={ checked }
        onChange={ onChange }
        { ...customProps as any }
      />
      <Slider />
    </Label>
  )
}

type InputProps = {
  readonly id: string,
  readonly checked: boolean,
  readonly onChange: () => void,
}
