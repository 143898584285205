import { MOBILE_THRESHOLD } from '@one-checkout/constants'

import styled from 'styled-components'
import { themeV2 as theme } from '@taglivros/tag-components'

export const ContainerGiftAddress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px !important;
  border: solid 1px ${theme.palette[ 'TAG1-100' ]};
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  @media(max-width: ${MOBILE_THRESHOLD}px) {
    margin-top: 0px;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const IndentificationDataSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const GiftLabel = styled.div`
  display: flex;
  align-items: center;
  
  span {
    margin-left: 5px;
    font-size: 20px;
  }
`
