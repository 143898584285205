import { AddressService } from '@checkout/services'
import { formatCep, onlyNumbers } from '@checkout/utils/formatter'
import store from '@one-checkout/store'
import identificationSlice from '@one-checkout/store/identification.slice'
import shippingSlice from '@one-checkout/store/shipping.slice'
import { setEventData } from './event.action'
import { getAbsoluteDateFromWorkDays } from '@one-checkout/utils/date'

export async function getAddress( postalCode: string ): Promise<{ identificationData: identificationSlice.State, shippingData: shippingSlice.State }> {
  const { product } = store.getState()
  const unformattedPostalCode = onlyNumbers( postalCode )

  let getAddressDataPromise

  if ( product!.isSubscription || product?.isFreeShipping ) {
    getAddressDataPromise = AddressService.get( unformattedPostalCode )
  } else {
    getAddressDataPromise = AddressService.get( unformattedPostalCode, product!.name as any )
  }

  const { 'data': addressData } = await getAddressDataPromise

  const commomSuccessData = {
    'isValid': true,
    'helperText': '',
    'disabled': false,
  }

  const identificationData: any = {
    'postalCode': {
      ...commomSuccessData,
      'value': formatCep( addressData.zipCode ),
    },
    'city': {
      ...commomSuccessData,
      'value': addressData.city,
    },
    'state': {
      ...commomSuccessData,
      'value': addressData.state,
    },
    'street': {
      ...commomSuccessData,
      'value': addressData.street,
    },
    'district': {
      ...commomSuccessData,
      'value': addressData.district,
    },
    'ibge': addressData.ibgeCode
  }

  const planMonths = product!.name.startsWith( 'yearly' ) ? 12 : 1
  const { isFreeShipping } = product!
  const shippingFee = isFreeShipping ? 0.0 : addressData.shippingFee

  const shippingData: any = {
    'price': shippingFee * planMonths,
    'monthlyPrice': shippingFee,
    'deadline': getAbsoluteDateFromWorkDays( addressData.workDaysDelivery ).toString(),
  }

  setIdentificationData( identificationData )
  setShippingData( shippingData )
  setEventData( {
    'shippingPrice': shippingData.price,
    'email': identificationData.email ? identificationData.email.value : '',
  } )

  return {
    identificationData,
    shippingData,
  }
}

export function toggleUseGiftAddress( state: boolean ) {
  const giftAddress: any = {
    ...store.getState().identification.giftAddress,
    'useGiftAddress': state
  }
  setIdentificationData( { giftAddress } )
}

export async function getGiftAddress( postalCode: string ): Promise<void> {
  const unformattedPostalCode = onlyNumbers( postalCode )
  const { 'data': addressData } = await AddressService.get( unformattedPostalCode )

  const commomSuccessData = {
    'isValid': true,
    'helperText': '',
    'disabled': false,
  }

  const {
    identification,
    product
  } = store.getState()

  const giftAddress: any = {
    ...identification.giftAddress,
    'postalCode': {
      ...commomSuccessData,
      'value': formatCep( addressData.zipCode ),
    },
    'city': {
      ...commomSuccessData,
      'value': addressData.city,
    },
    'state': {
      ...commomSuccessData,
      'value': addressData.state,
    },
    'street': {
      ...commomSuccessData,
      'value': addressData.street,
    },
    'district': {
      ...commomSuccessData,
      'value': addressData.district,
    },
    'ibge': addressData.ibgeCode,
  }

  const planMonths = product!.name.startsWith( 'yearly' ) ? 12 : 1
  const { isFreeShipping } = product!
  const shippingFee = isFreeShipping ? 0.0 : addressData.shippingFee

  const shippingData: any = {
    'price': shippingFee * planMonths,
    'monthlyPrice': shippingFee,
    'deadline': getAbsoluteDateFromWorkDays( addressData.workDaysDelivery ).toString(),
  }

  setIdentificationData( { giftAddress } )
  setShippingData( shippingData )
  setEventData( { 'shippingPrice': shippingData.price } )
}

export const getShippingInfoPillText = () => {
  function parseDate( date: Date ): string {
    return date.toLocaleDateString( 'pt-BR', { 'dateStyle': 'short' } ).replace( /\/\d+$/, '' )
  }
  const {
    product,
    shipping,
  } = store.getState()
  const shippingDeadline = new Date( shipping.deadline )
  const {
    shippingFrom,
    isFreeShipping
  } = product!

  if ( isFreeShipping ) {
    if ( shippingFrom ) {
      return `Frete Grátis. Envios a partir de ${parseDate( new Date( shippingFrom ) )}`
    }
    return `Frete Grátis. Entrega até ${parseDate( shippingDeadline )}`
  }
  return `+R$${shipping.monthlyPrice!.toFixed( 2 )}/mês. Entrega até ${parseDate( shippingDeadline )}`
}

export function setIdentificationData( identificationData: any ) {
  store.dispatch( identificationSlice.actions.setIdentificationData( identificationData ) )
}

export function resetEmail() {
  store.dispatch( identificationSlice.actions.resetEmail() )
}

function setShippingData( shippingData: shippingSlice.SetShippingDataPayload ): void {
  store.dispatch( shippingSlice.actions.setShippingData( shippingData ) )
}
