import stepSlice from '@one-checkout/store/step.slice'
import store from '@one-checkout/store'

let alreadySet = false

export function setCollapseIdentificationForm( colapseIdentificationForm: boolean ) {
  if ( colapseIdentificationForm && !alreadySet ) {
    alreadySet = true
    store.dispatch( stepSlice.actions.setCollapseIdentificationForm( colapseIdentificationForm ) )
  }
  if ( !colapseIdentificationForm ) {
    store.dispatch( stepSlice.actions.setCollapseIdentificationForm( colapseIdentificationForm ) )
  }
}
