import { formatCep, formatPrice } from '@checkout/utils/formatter'
import { TypographyV2 as Typography } from '@taglivros/tag-components'
import React from 'react'
import DiscountLabel from './DiscountLabel'
import ShippingLabel from './ShippingLabel'
import * as S from './styles'
import { useSelector } from 'react-redux'
import store from '@one-checkout/store'

function IdentificationSummary( props: IdentificationSummary.Props ) {
  const { customer, address, shippingPrice, hideShippingInfo, discount, onClick } = props
  const { identification } = useSelector( ( state: store.State ) => ( {
    'identification': state.identification
  } ) )

  return (
    <S.Container onClick={ onClick }>
      {
        !identification.giftAddress.useGiftAddress ? null :
          <S.Row>
            <S.Label>
              <Typography
                color="TAG1-700"
                variant="text-medium-bold"
              >
                Endereço de cobrança
              </Typography>
            </S.Label>
          </S.Row>
      }
      <S.Row>
        <S.IndentificationDataSection>
          <Typography
            color="TAG1-700"
            variant="text-medium-bold"
          >
            {customer.name}
          </Typography>
          <Typography
            color="TAG1-700"
            variant="text-small-regular"
          >
            {customer.email}
          </Typography>
        </S.IndentificationDataSection>
        {discount > 0 && <DiscountLabel value={ discount } />}
      </S.Row>
      <S.Row>
        <S.IndentificationDataSection>
          <Typography
            color="TAG1-700"
            variant="text-medium-bold"
          >
            {formatAddressLine1( address )}
          </Typography>
          <Typography
            color="TAG1-700"
            variant="text-small-regular"
          >
            {formatAddressLine2( address )} {shippingPrice > 0 && <b>+{formatPrice( shippingPrice, true )}</b>}
          </Typography>
        </S.IndentificationDataSection>
      </S.Row>
      {
        hideShippingInfo || identification.giftAddress.useGiftAddress ? null :
          <S.Row>
            <ShippingLabel />
          </S.Row>
      }
    </S.Container>
  )
}
namespace IdentificationSummary {
  export interface Props {
    customer: Customer
    address: Address
    shippingPrice: number
    discount: number
    hideShippingInfo?: boolean
    onClick(): void
  }

  export interface Customer {
    name: string
    email: string
  }

  export interface Address {
    street: string
    number: string
    complement: string
    city: string
    state: string
    postalCode: string
  }

}

function formatAddressLine1( address: IdentificationSummary.Address ): string {
  const { street, number } = address
  return `${street}, ${number}`
}

function formatAddressLine2( address: IdentificationSummary.Address ): string {
  const { complement, city, state, postalCode } = address
  return `${complement ? complement + '. ' : ''}${city} - ${state}. CEP ${formatCep( postalCode )}`
}

export default IdentificationSummary
