/* eslint-disable max-statements */
import { FieldId } from '@one-checkout/interfaces/FormFields'
import { Form } from '@one-checkout/components/Form'
import React, { useState } from 'react'
import { FormInput } from '../FormInput'
import { Spinner } from '@checkout/components/atoms/Spinner'
import { FormInfoPill } from '../FormInfoPill'
import store from '@one-checkout/store'
import { HelpCircle, Truck } from 'react-feather'
import { getGiftAddress, setIdentificationData, getShippingInfoPillText } from '@one-checkout/actions/identification.action'
import { useSelector } from 'react-redux'
import { validate } from '@one-checkout/utils/validation'
import { applyCoupon } from '@one-checkout/actions/promotionCoupon.action'

export function GiftAddressForm() {
  const { giftAddress, promotionCoupon, product } = useSelector( ( state: store.State ) => ( {
    'giftAddress': state.identification.giftAddress,
    'promotionCoupon': state.promotionCoupon,
    'product': state.product
  } ) )
  const [ waitingAddress, setWaitingAddress ] = useState( false )

  async function handleChange( id: FieldId, value: string, isValid: boolean, helperText: string ): Promise<void> {
    updateField( id, value, isValid, helperText )

    if ( id === 'postalCode' && isValid ) {
      try {
        setWaitingAddress( true )
        await getGiftAddress( value )

        if ( promotionCoupon.state === 'applied' && giftAddress.useGiftAddress ) {
          await applyCoupon()
        }
      } catch ( error ) {
        updateField( id, '', false, helperText )
      } finally {
        setWaitingAddress( false )
      }
    }

    fieldValidation( 'complement', id, value )
    fieldValidation( 'district', id, value )
  }

  const updateField = ( id: string, value: string, isValid: boolean, helperText: string ) => {
    setIdentificationData( {
      'giftAddress': {
        ...giftAddress,
        [ id ]: {
          value,
          isValid,
          helperText,
        }
      }
    } )
  }

  const fieldValidation = ( fieldId: FieldId, id:string, value: string ) => {
    if ( id === fieldId ) {
      const validation = validate( fieldId, value )
      updateField( id, value, validation.isValid, validation.helperText )
    }
  }

  const postalCode: FormInput.Props = {
    ...giftAddress.postalCode,
    'label': 'CEP',
    'id': 'postalCode',
    'maxLength': 10,
    'Icon': () => {
      return waitingAddress ? (
        <Spinner
          show={ true }
          isColored={ true }
          width={ 25 }
          height={ 25 }
        />
      ) : (
        <HelpCircle
          className="is-clickable"
          onClick={ () => window.open( 'https://buscacepinter.correios.com.br/app/localidade_logradouro/index.php', '__blank', 'noopener' ) }
        />
      )
    },
    'InfoPill': giftAddress.postalCode.isValid && !product?.hideShippingInfo ? () => (
      <FormInfoPill
        Icon={ Truck }
        text={ getShippingInfoPillText() }
        backgroundColor="#222F641A"
        color="TAG1-700"
      />
    ) : null,
  }

  const city: FormInput.Props = {
    ...giftAddress.city,
    'disabled': true,
    'label': 'Cidade',
    'id': 'city',
    'maxLength': 40,
    'inputWidth': {
      'mobile': '75%',
      'desktop': '75%',
    }
  }

  const state: FormInput.Props = {
    ...giftAddress.state,
    'disabled': true,
    'label': 'Estado',
    'id': 'state',
    'maxLength': 2,
    'inputWidth': {
      'mobile': '25%',
      'desktop': '25%',
    },
  }

  const street: FormInput.Props = {
    ...giftAddress.street,
    'label': 'Rua',
    'id': 'street',
    'maxLength': 60,
  }

  const number: FormInput.Props = {
    ...giftAddress.number,
    'label': 'Número',
    'id': 'number',
    'maxLength': 5,
    'inputWidth': {
      'mobile': '25%',
      'desktop': '25%',
    },
    'autoFocus': true,
  }

  const complement: FormInput.Props = {
    ...giftAddress.complement,
    'label': 'Complemento',
    'id': 'complement',
    'maxLength': 50,
    'inputWidth': {
      'mobile': '75%',
      'desktop': '75%',
    },
  }

  const district: FormInput.Props = {
    ...giftAddress.district,
    'label': 'Bairro',
    'id': 'district',
    'maxLength': 50,
  }

  const formFields = [
    [ postalCode ],
    [ city, state ],
    [ street ],
    [ number, complement ],
    [ district ]
  ]

  return (
    <Form
      inputFields={ formFields }
      onSubmit={ () => null }
      onChange={ handleChange }
    />
  )
}


export namespace GiftAddressForm {
  export interface Fields {
    'postalCode': FormInput.Props,
    'city': FormInput.Props,
    'state': FormInput.Props,
    'street': FormInput.Props,
    'number': FormInput.Props,
    'complement': FormInput.Props,
    'district': FormInput.Props,
  }
}


